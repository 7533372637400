import store from '.'
import Modal from "@/components/Modal.vue";
import Vue from "vue";

export const handleError = (error) => {
    if (error.response.status === 400) {
        let msg = ''
        let type = 'POPUP'

        if (error.response.data.length < 1) {
            msg = error.response.headers['response-message']
        } else {
            msg = error.response.data['response-message']
            type = error.response.data['message-mode']
        }

        if (type === 'POPUP') {
            store.dispatch('saffe/setLoad', false).then(() => {})
            store.dispatch('saffe/clear', false).then(() => {})
            new Vue({
                el: '#app',
                render: h => h(Modal, {
                    props: {
                        opened: true,
                        doit: () => setTimeout(() => window.location.href = "/instructions", 800)
                    },
                    data: {
                        conteudo: msg
                    },
                }, [msg])
            })
        } else {
            if (msg?.includes('data de nascimento informada não confere')) {
                store.dispatch('flux/nextStep', 'VALIDATE_SMS')
            } else if (window.location.href === '/selfie2') {
                let unico = localStorage.getItem('unico')
                console.log('test unico')
                if(unico === 'S' || window.location.pathname === '/selfie2') {
                    store.dispatch('unico/OPEN').then(() => {})
                } else {
                    store.dispatch('saffe/setLoad', false).then(() => {})
                    store.dispatch('saffe/clear', false).then(() => {})
                    store.dispatch('message/sendError', msg)
                }
                // if(window.location.pathname !== '/proposal')
                //     store.dispatch('flux/nextStep', 'VALIDATE_SMS')
            }
            else {
                store.dispatch('message/sendError', msg)
                // store.dispatch('flux/nextStep', 'VALIDATE_SMS')
            }
        }

    } else if (error.response.status === 401) {
        let msg = ''
        let type = 'ALERT'

        if (error.response.data.length < 1) {
            msg = error.response.headers['response-message']
        } else {
            msg = error.response.data['response-message']
            type = error.response.data['message-mode']
        }

        if (type === 'POPUP') {
            store.dispatch('saffe/setLoad', false).then(() => {})
            store.dispatch('saffe/clear', false).then(() => {})
            new Vue({
                el: '#app',
                render: h => h(Modal, {
                    props: {
                        opened: true,
                        doit: () => ""
                    },
                    data: {
                        conteudo: msg
                    },
                }, [msg])
            })
        } else {
            store.dispatch('message/sendError', msg)
            // setTimeout(() => window.location.href = "/", 4000)
        }
    } else {
        store.dispatch(
            'message/sendError',
            'Não foi possível completar a requisição. Por favor, tente novamente mais tarde.',
        )
    }
}
