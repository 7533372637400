import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueCompositionAPI from '@vue/composition-api'
import hooks from '@u3u/vue-hooks'
import VueTheMask from 'vue-the-mask'
import Notifications from 'vue-notification'
import vmodal from 'vue-js-modal'
import VueScrollPicker from '@/components/ScrollPicker/Scrolldatepicker'
import Modal from "@/components/Modal.vue";
import FontAwesomeIcon from './fontawesome'

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(vmodal)
Vue.use(VueScrollPicker)

Vue.component('customModal', Modal)

Vue.use(Notifications)

Vue.use(VueTheMask)
Vue.use(hooks)
Vue.use(VueCompositionAPI)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
