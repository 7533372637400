import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    loading: modules.loading,
    auth: modules.auth,
    flux: modules.flux,
    sign: modules.sign,
    upload: modules.upload,
    message: modules.message,
    selfie: modules.selfie,
    proposal: modules.proposal,
    geolocation: modules.geolocation,
    partner: modules.partner,
    validNavigator: modules.validNavigator,
    unico: modules.unico,
    saffe: modules.loadSaffeSDK
  },
})
