const state = {
    loadSDK: true,
    sdk: null,
}

const getters = {
    loadSDK: (state) => state.loadSDK,
}

const actions = {
    setLoad({commit}, load) {
        commit('SET_LOADSAFFESDK', load)
    },
    loadSaffeSDK({commit}, captureCallback) {
        commit('LOADSAFFE', captureCallback)
    },
    clear({commit}) {
        commit('CLEAR')
    }
}

const mutations = {
    SET_LOADSAFFESDK(state, load) {
        state.loadSDK = load
    },

    async LOADSAFFE(state, captureCallback) {
        const key = `xsFNBGN/ersBEADAsain/bIkzlBYzgQaWvdQXO3CRz1nms08ak1NSWPEGtmFo7H0
7GfvAIDS34g6LwUscgxA87Nn9rYLbz1erPiBMuvDZixA4m2DWwKYSLBLajNG3ZmK
eaQgdsHobD5zNe6/h7sK6VyBhw3vlHdBDsFApeRjIitUVLbdULTY636VByOjuuaX
A6zUUEvmjYf3SPzZ64M1g8pCzR8vZ5uXQ7fuZQDmmMgJ2EoVznkIr2fbGbK2EXoc
pSI+OIAgWk9+1DjGR5YshvoYNOeg/xgzO7ZbWAYbfNXbagJZ4aEdyl1IE1Cxp1ZT
qiWSzpAN7GgAJe1HIEXmSOHSRrmBbFl54FNcTUenTk3z8OMVZbxSrYxWHsKs+NWT
9seWTq4RWnlQFVtYqzYWlDGzejPvuGvipaMC2emojVGI2Y1dV0Sc7Qw4hn29eeWg
UTOUCVPUegTuHt1MjTlkB0hMKH23H0pvUZbKBWTN4GHaFWj4lfXT4MH5J+uc/cbb
e/Cs8mevrzxKyf6NHX5srD94mr2uE/emfB0ZAT1BWjAJuffa5WmlXZnQcn/Cnbtc
jXSxOBbYRHhV6CsyK879Rn94UPEOLmh8j0pyPQDfMKmfRQTAX8+BjjQq6TSNeTnQ
qz2zyFOgfjvbKSxWpEsC0SqDSjZRCpSeHce5GnHerXJ9x1g8MgRKxwdhNQARAQAB
zTxOM3h5MG4gKFBHUCBLZXkgZm9yIFNhZmZlIGxpdmVuZXNzKSA8bWF1cm92Zm1A
bmV4eW9uLmNvbS5icj7CwW0EEwEKABcFAmN/ersCGy8DCwkHAxUKCAIeAQIXgAAK
CRDp+blhqI8gbCeJD/0TlLgXOySG2Jv3iBQ31zvQGJnZ8B+lbMyM5OEvOXS6vwUh
4LvUbd1hKJVlJlFaFFUaJ3PRcs+4vuoBEzoJg56JmBZiOHVuAhBXKV/9TRNCBVml
Wr/6nfmJtjFhpAmycwhvYSnn3+DqpUW1kDskvbx9tYb3Zgorj+xN3eQcrZeMkx+8
sVBwtEMgEfGHmxkzgQj2SuzrdnCpeItVdvtIBG2Fg7wUifKnVzdHC3nRKmHyEmQc
wS8iQ45GibTa5oqWOiEEEdmxypfl3YijjsG1yR4F7+bWK2ceq2nN8TKP/o6wBXoq
4l2rd2VUkdbjpxCk7BtfDMTCJI7Ypb+t97wc1ALeF9ur4fY9Mf3IIxkzj9xx/Y/R
VEfFJQ5wdgLj52+zA+ysg8jC2c95T567sdaL1oI4gyER7HlzXcS0d3Cvb53Kexbh
4gFlymyfuWDRQJLRYq6LCDZYzm3v1LzZzg1OQ6Wj1V++rxEDfsA+7Chy0C7EPk0v
6d07j3Qtv6ZnPTunvpkPA6YvJnLuYkxZuFoIwPi1UHCJBt2s54yJWYGbkG4Rvdau
tlYpPXbQi++CjHILRPSVNEp5BBgc8u+t3k6HAHSrur1mQm2ZjaE26Iv2Aq4bEZS5
29tdK3BZcLEslDY3DQ2acsTSkI6jlkBMfrq8QYt6oW0VUqSekS922WmdX0vJxc7B
TQRjf3q7ARAA5JSto7tbWbKDYOkGrntiX04XSNDw0It7R43PKzt9yUWBhuq+jQcc
K+b3hj0tAAVTsupUvXsdvg1uvs0qvmeuhByj8hGGLsgcoeoZhhnfK+K6zBm5JkYA
/I3Y+mMSHDW0Rq//PhxUfkbwGGHIsqxcUN6tKymUWG79MwxCTE+LIDze/Z2QdN8S
Jbe6gde7OmSG399T9zR4wZGqIAeOXU3Fg6D3pWivVCQOoUZGKR6pZt2cEs4LmhM2
P1QvohPK1WYQMp9Os4zYpVduWIOgYsxcKO6LR4u9H8PPqTbiR6op0lWv8rAm1uEp
IeCGF/w9C+q6cFktLneAVHrddQR6TaW8JOISDNKUuPNk2VS7vMOSm4gpR55BQpZR
M8lbntq9NPgDevumZg43jHio5m2LqCTqA6KN19mAAMqoVEU5zEUJWQSS6V0FbuqZ
SMkE4gnM2cIexS38ixMJ2KzhkB7eqie4SbaDzcAb/btNwKGxMUBHNB16Lz8S7e1K
1ikkgrgJpWgndGqlNH1qAVPz411nkMOorVD6s67rYFrjG8FzE3YYn7EBgR6NWuay
dFgz/2jotd5HRTe+ef6gwnTJpwHqho6Jp/EHqkDewflq/LZbZP1xoYtnXbxrqQVl
iYmUbh5CJ8taSIVPoKgQtVU6lMkHTc/RKNbdlPlMhvP8AR3fSbkP89UAEQEAAcLD
hAQYAQoADwUCY396uwUJDwmcAAIbLgIpCRDp+blhqI8gbMFdIAQZAQoABgUCY396
uwAKCRBO3mQhEOfoCVOpD/9Lsu4ahy1hco9K18R02RAn7sI+7LgOwuAag0+BbVW4
MQ3BtOssZkV4xnN0zRLHzDQSUz2FUD1uwHVeNJnzq99f392XduC46xnwepu7fQ0t
ry7rjZ7PSAbCauHPIjA+ckkiBFGVF+lcg3Qb1Ej1hZKWroVz67GLrJ7Qun7eJmuO
p5w5wKkE4z48+qUrXArm4j/HUd3npCYlsi+G7oYq5VuLLrHcgK8i9ab+g+wQfKfH
C06s7XHWwrfLdRldp6Kk5zGZ1UQ+SdECvqBvcmpgwbVYH20lOcMqFtbo07ryvupc
/WAHELddwHX7EAYU6JmDqUIK1OpnROtWJzxV0SGHzlGXvERjmuBnPSt1NnG8sns2
oGrzmbvmnglpUyzIv3AFHoSdwhm0caIf9Vw5Je/FTEnMq9+xNgoQD/qbvpg5liG4
D6SQ8e4/rx1Al0yNXXaP5Vf0OfzNG864pk+IkMzdZ1q8xD11LoBOicOLFRoXZhvZ
DhtxOFR0LujvlEvQhRxYitQLcQIPMrw3jiieeejobEGBVgAwrTpN7/4BVdGhHEKt
5Y2nN0STj45lmqyt8yN1w+YBv5XQomTUGuYvlqsxOr9FdwVIo/LEgtG4hA19Ve3T
Fny2SYa52n+8qoVcQ1mhmBCXnKfxYb2oC1expLQH/UOr8ctfA6ve0uuJGYkkecO6
qd3FD/0eTZ/Ur9YKaiU1Q862WTE5RpbIImzuos9YIPguHVneOKTWdmOFtuXye/7k
hiJUgtnmH45zGVXWz4qc3d4v+FIJXT6XBLCX+ST0m/GouaBmLnCfIS15TzG3RHDk
my9ax/APjFV2ZnAp7EsqoBDbKuJdR2/S25Q+sM7BmzYL+QbCp/WEJIsz2Y1SIyot
4WJ2fDaXTBI84ubiEGf6TAuQcYIG0cNNQ3Y9V6RARNm1r/3L7zzfX2Od9PItPJC8
1OCml30W6tg+FOx4UyamuPksX3re8bEYZ2nMbYo6EVpEihFx3pLNhs42Yt/jHDl5
YV4/q0/GmC/ayKs5wI9bNiSoIZYPoY7a7vQbAFw44N4M21LPo1y8prFfkiM9fj24
uv6cTRqPxh3DgFoLg4yxgrkem/x5lBXxC9Y5sL8JtKK/RkSOzE43QeFBYIdwTC70
g5CpTdxdz3kx0kVNn2mFzY1/jpRTFJ0tMbUuKTsQOeeG8rLcazN75oJVOeKVkHkI
mxkfEZfMP/b4T6+20AnvIEKhFX1VP08JBFR2ruKVdFUz7fa9FBb0XJck3P1Ixql8
wV7fVAg0EiTTmCpflziPmDqwFEQ9uLbaN0K3fUHqy1nAOKL7CoIwfYKIHiK92vki
cJ4uCWXEeBEecStCvFJD27B7YygUrQEsNj4IJTT3PMVLihPIPc7BTQRjf3q7ARAA
006Xp85o6C8Aj1KJDbNi3plef2d89q/TskrGKYdyYbyJguAzw1PkDkDIXANQyTfU
gUx8rYSvgdfVstKjsh53LEEy6rVmtJUrq6hNCEgzfIR3Vxv68bQ2zu+RDGsAKICc
sHHh8bg/r8k/JYKsNdjBMGq5QGJwgbNGubvI7LFhhQ04liYROJYmbQwQrHVaskLb
nux6fhLZv8xHjXwh20tk5JL1SK8EsvFqAHHnaoy+qDjWKv3jzgMxmKWw3PYE1T4a
U6tLtKdusrYo/5o7LwHtv56lq9DUXP1NE/+OY7YNpjAuaq+XlDvc5ez/JPfZnO/a
nJPrStDkNBr9O8qijQla79NFJRs/SbTvPFu4bIkfYf0HVI+ILb5AUNOxE4yyWI0H
jQo1Ix+fscTrqfhf2yndXULhOsmM0tvU6u7wOr/1nURTGQPXhMjTQ+0E85Ls/NcE
dQxdDxnBfgwHQcyGrY2tZagAAnPt/p1rPFcNez0/3J+rzh9zbXKPA1zCqwFItpJR
MaVZr/yZ7UzthiIqZzM+KwoVY1wEhUOd35zsEJsHS/ooEsoNRtAKrqLebDns9DKd
JfDT8vZgLOY7idtOgINsJwq3znRDVhYwlQyBmWBVvTVzydvtEteY3mCEPeuq+DeT
bljW+9soc1VZ0rd10YDiVBe4lGRcn61I9oAeYhVFOSsAEQEAAcLDhAQYAQoADwUC
Y396uwUJDwmcAAIbLgIpCRDp+blhqI8gbMFdIAQZAQoABgUCY396uwAKCRA53JqP
tr1mTXIYEAC+q7AatCPWvfI0qYWWfN+FDOAs8D7YQbqrpfTtzZbjW/UsKDy3xY4Z
FF2dkH7F2fiJpf5uR/wypRQTOKgOpThn7jFvI8NEQgO+dgA+HJTATW1af83jXoIi
DSksOsNnqhDElrDzTVXFRDb11Nfxq1Rec0LWQ06Os4XAqc9YpEleGg3WRNSNjm7C
eACCfMO1tjZYb2WhuEwrq9zIGg9VNfK+C/Q5URptl5WbpFUnKPpNwZJyrGxiKS3F
VEI5fwpu268QxncheImUHq7Wz89ABHRTlhrskHB9/PHcSBaOwjjfJXl2U35zXX8f
wDdfGoaMWFP4rDS6QRw4awe6tHbtc9weSiLG7Lkgg63+JYuWvQSygm13p/kKFmYC
O3UrdBwFuEeNh48MjA6wgL6RFNmKYJqKzB5+LmntsYVGgjx/oydodXxsjlJbFVH4
JMCx6LyXAOf4CKm/qgZL+6oULR3yvUVoSLjtX9CsMqCvr8IS+IlTC/RXLxYORjbe
838Wvq6J3JoMM4imYi/7hzESwHzXAtwnHtiiNdOjA/hE6QzcX/0E553Cxcdj+aXc
2Fut6CMtXrLOnMP9V60OtKb8Y231wY9i+l5bTmIvHcvvA/ES7bfSD3GNkRP5ZxOc
Xho5c1u+EbfyNPvWUFWkCGHkeQdPEy+FT1+moDXy2E8AwbKNiFHLMp53D/9bWTUY
9kktnqNDuP28srWouEbMPVYxYzSxeBNwIkcyCQifhtLMgBfzfctThlBNoCgAJ48H
ck1CtmkWVWKtpDUNzcOLnFh3TnK6wvr8A90FgWzRgYl5jOowq3H6G0dYGVW4xJK1
a6t6bNw2lJ6YdtlF/Hb1v2udm/9c96vgS3mBXc/UHTYbyKExDwyHTmPenrJVz2hR
BQQD9eC96qhSukEaJ94hOS1zlWrMSHwky52f+zSeFvRXNVLvOmr4s/kg71/bgCaP
NsWx6JGI0ORROnFmLT0J74HyLwsyyvXzkB5oiJzANuVPoNWx2bGnF42ZCMihsb4k
dKSZwExwrhrlD1Hu5wGlSKPIvKnDeIITNcrgau7F7siQioXdUXZvtN5opbtTPiiC
Z46BqzxPetGxRhkwz+XkmQLgdghepfe6CwGE8hPt1H1WuoY4yGz7cwFxdl+zSG9W
oqGJkAKmHuUCh8QHO5SQSSpeFIp9aj7tYnvQesT95KRqAohPetY0nq3/OnQpWVQU
15hAqt9IMtIo3DKYUhpIWTanO4+YiXYR6ySZMYhXV+l2gaXiYwWt8OTIRNWKury2
qBdpFAja061F+jTEfRZxNsv6R0/IqNkkN9R1mlZ7yLbDKHY3vHNtmsvpoJbDAVeL
IvkVwo6JiW8rli/uuB2J4zfu320O73II2LicRg==
=uohg`
        let saffeScript = document.createElement("script");
        saffeScript.setAttribute("src", "/SaffeSDK/SaffeCapture-0.16.1-beta.js");
        await document.head.appendChild(saffeScript);
        console.log(`comp saffe ${JSON.stringify(window.SAFFE)}`);
        await window.SAFFE.init.twoStepsCapture({
                lang: "pt-br",
                // Defina as cores primária e secundária do componente;
                primaryColor: "#c1c1c1",   // opicional; padrão: #49a39b (hexadecimais ou rgb)
                secondaryColor: "var(--first-color)",   // opicional; padrão: #fff (hexadecimais ou rgb)
                timeout: 12000,            // opicional; padrão: 15000 (milisegundos)
                instructions: false,
                preCaptureSvg: '',
                debug: true, //optional; default: false. When its false, the browser's developer tools isnt allowed)
                fontFamily: 'inherit', //optional; default: 'inherit'
                borderWidth: 5, //optional; default: 5 (integer in pixels)
                custom_messages: {
                    PRE_INSTRUCTIONS: {
                        TITLE: "O tempo para validar a foto expirou.",
                        P: "<b>Vamos tentar novamente?.</b>",
                        SPAN: "Siga as instruções para uma boa captura:",
                        LIST: ["Vá para um local bem iluminado;", "Retire óculos, máscaras, bonés e outros itens que cubram seu rosto", "Mantenha seu celular na posição vertical e na altura do rosto"],
                        CTA: "<b>Tentar novamente</b>"
                    }
                }
            },
            captureCallback,
            key
        );
    },

    CLEAR() {
        this.processedPhoto = null;
        if (this.photo && this.photo.style) {
            this.photo.style.display = "none";
            this.camera.style.display = "flex";
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
