import axios from 'axios'
import store from '.'
import router from '../router'
import { handleError } from './handleInterceptorHelper'
const baseURL = process.env.VUE_APP_API_URL

const instance = axios.create({ baseURL })
const selfinstance = axios.create({baseURL:'http://localhost:3000/'})
const TIME_TO_LOGOUT = 1000 * 60 * 20
let timeout = undefined

function getToken() {
  const storedToken = store.getters['auth/token']
  const localStoredToken = localStorage.getItem('TOKEN')

  if (storedToken) {
    return storedToken
  } else if (localStoredToken) {
    return localStoredToken
  }

  return null
}

function setTimeOutLogout() {
  timeout = setTimeout(() => {
    localStorage.removeItem('TOKEN')
    localStorage.removeItem('PROPOSAL_ID')
    store.dispatch('auth/updateToken', undefined)
    store.dispatch('message/sendError', 'Sua sessão expirou!')

    router.push({
      path: '/',
    })
  }, TIME_TO_LOGOUT)
}

instance.interceptors.request.use(
  async function(config) {
    if(config.url === '/api/login')
            store.dispatch(
                'loading/setLoadingWithMessage',
                'Validando dados...',
            )
    else if(config.url === '/api/available-proposals-list')
        store.dispatch(
          'loading/setLoadingWithMessage',
          'Verificando lista de Propostas...',
        )
    else if(config.url === '/api/proposal-choice')
        store.dispatch(
          'loading/setLoadingWithMessage',
          'Verificando proposta...',
        )
    else if(config.url === '/api/token')
        store.dispatch(
          'loading/setLoadingWithMessage',
          'Validando token...',
        )
    else if(config.url === '/api/selfie')
        store.dispatch(
          'loading/setLoadingWithMessage',
          'Analisando sua Foto...',
        )
    else if (config.url !== '/api/sign-document')
      store.dispatch('loading/setLoading', true, { root: true })

    if (timeout) window.clearTimeout(timeout)

    setTimeOutLogout()

    const token = getToken()

    if (token) {
      config.headers['session-token'] = token
    }

    const geolocation = store.getters['geolocation/geolocation']

    if (geolocation) {
      config.headers['latitude'] = geolocation.latitude
      config.headers['longitude'] = geolocation.longitude

      localStorage.setItem('ACCEPT_TOKEN', true)
    }

    return config
  },
  function(error) {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
    response => {
        if (response.config.url !== '/api/sign-document')
            store.dispatch('loading/setLoading', false, { root: true })
        const token = response.headers['session-token']
        if (token) {
            store.dispatch('auth/updateToken', token, { root: true })
            localStorage.setItem('TOKEN', token)
        }
        return response.data
    },
    error => {
        store.dispatch('loading/setLoading', false, { root: true })
        handleError(error)
        throw error
    },
)
selfinstance.interceptors.response.use(
    response => {
        if (response.config.url !== '/api/sign-document')
            store.dispatch('loading/setLoading', false, { root: true })
        const token = response.headers['session-token']
        if (token) {
            store.dispatch('auth/updateToken', token, { root: true })
            localStorage.setItem('TOKEN', token)
        }
        return response.data
    },
    error => {
        store.dispatch('loading/setLoading', false, { root: true })
        handleError(error)
        throw error
    },
)

export default instance
