const state = {
  partner: '',
}

const getters = {
  partner: (state) => state.partner,
}

const actions = {
  async setPartner({ commit }, partner) {
    commit('SET_PARTNER', partner)
  },
}

const mutations = {
  SET_PARTNER(state, partner) {
    console.log(window.location.pathname === "/" || window.location.pathname === "/ford" || window.location.pathname === '/harley')
    if (window.location.pathname === "/" || window.location.pathname === "/ford" || window.location.pathname === '/harley') {
      console.log('SET_PARTNER', partner)
      state.partner = partner
      localStorage.setItem('partner', partner)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
