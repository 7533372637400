import auth from './auth'
import loading from './loading'
import sign from './sign'
import upload from './upload'
import message from './message'
import selfie from './selfie'
import flux from './flux'
import proposal from './proposal'
import geolocation from './geolocation'
import partner from './partner'
import validNavigator from './validNavigator'
import unico from './unico'
import loadSaffeSDK from "@/store/modules/loadSaffeSDK";

export default {
  loading,
  auth,
  sign,
  upload,
  message,
  selfie,
  proposal,
  flux,
  geolocation,
  partner,
  validNavigator,
  unico,
  loadSaffeSDK
}
