export const loading = (dispatch, bool) => {
  dispatch('loading/setLoading', bool, { root: true })
}

export const loadingWithMessage = (dispatch, message, bool) => {
  dispatch('loading/setLoadingWithMessage',
    message, bool, { root: true })
}

export const FLOW_PAGES_ENUM = {
  LOGIN: 'LOGIN',
  VALIDATE_SMS: 'VALIDATE_SMS',
  INSTRUCTIONS: 'INSTRUCTIONS',
  PROPOSAL: 'PROPOSAL',
  SELFIE: 'SELFIE',
  UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
  SIGN_DOCUMENTS: 'SIGN_DOCUMENTS',
  FINISHED: 'FINISHED',
}

export const FLOW_PAGES = {
  LOGIN: '/',
  VALIDATE_SMS: '/validate-sms',
  INSTRUCTIONS: '/instructions',
  PROPOSAL: '/proposal',
  SELFIE: '/selfie',
  SELFIE2: '/selfie2',
  UPLOAD_DOCUMENTS: '/upload-documents',
  SIGN_DOCUMENTS: '/sign-documents',
  FINISHED: '/finished',
}

export let FROM_TO = {
  LOGIN: FLOW_PAGES.VALIDATE_SMS,
  VALIDATE_SMS: FLOW_PAGES.PROPOSAL,
  PROPOSAL: FLOW_PAGES.INSTRUCTIONS,
  INSTRUCTIONS: FLOW_PAGES.SELFIE,
  SELFIE: FLOW_PAGES.UPLOAD_DOCUMENTS,
  UPLOAD_DOCUMENTS: FLOW_PAGES.SIGN_DOCUMENTS,
  SIGN_DOCUMENTS: FLOW_PAGES.FINISHED,
  NOT_AUTHORIZED: FLOW_PAGES.LOGIN,
}
