import router from '../../router'
import {FLOW_PAGES, FLOW_PAGES_ENUM, FROM_TO} from '../common'
import axios from '../axios-interceptor'
import store from '../'

const state = {
  currentStep: null,
  nextStep: null,
  nextStepDetails: null,
}

const getters = {
  currentStep: (state) => state.currentStep,
  nextStep: (state) => state.nextStep,
  nextStepDetails: (state) => state.nextStepDetails,
}

const actions = {
  /**
   * @deprecated Since version 1.9.19
   */
  setSteps({ commit }, steps) {
    commit('SET_CURRENT_STEP', steps['current-step'])
    commit('SET_NEXT_STEP', steps['next-step'])
    commit('SET_NEXT_STEP_DETAILS', steps['next-step-details'])

    // const nextStep = steps["next-step"];

    // const to = fluxesPages[fluxes[nextStep]];

    // router.push({ path: to });
  },

  nextStep({ commit }, from) {
    const partner = store.getters['partner/partner'] || ''

    commit('SET_CURRENT_STEP', from)
    let to = partner ? `/${partner}${FROM_TO[from]}` : FROM_TO[from]
    if(from === FLOW_PAGES_ENUM.INSTRUCTIONS) {
      const unico = localStorage.getItem('unico')
      if(unico === 'S')
        to = partner ? `/${partner}${FLOW_PAGES.SELFIE2}` : FLOW_PAGES.SELFIE2
    }

    if (to) {
      router.push({ path: to })
    } else {
      console.error(from, ' Not recognized as regular FROM')
    }
  },

  async finishJourney({ commit }) {
    commit('SET_CURRENT_STEP', 'FINISH')

    await axios.post('/api/finish-journey', {})
  },
}

const mutations = {
  SET_CURRENT_STEP(state, currentStep) {
    state.currentStep = currentStep
  },

  SET_NEXT_STEP(state, nextStep) {
    state.nextStep = nextStep
  },

  SET_NEXT_STEP_DETAILS(state, nextStepDetails) {
    state.nextStepDetails = nextStepDetails
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
