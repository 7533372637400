import { loading, FLOW_PAGES_ENUM } from '../common'
import instance from '../axios-interceptor'
// import selfinstance from '../axios-interceptor'
import store from "@/store";

const state = {
  selfieStatus: [],
}

const getters = {
  selfieStatus: (state) => state.selfieStatus,
}

const actions = {
  setUploadStatus({ commit }, selfieStatus) {
    commit('SET_SELFIE_STATUS', selfieStatus)
  },

  async retrieveSelfieStatus({ commit, dispatch }) {
    try {
      const data = await instance.get('/api/selfie-status')

      if (data) {
        const parsedData = JSON.parse(data['next-step-details'])
        commit('SET_SELFIE_STATUS', parsedData)
      }
    } catch (error) {
      console.error(error)
    } finally {
      loading(dispatch, false)
    }
  },

  async uploadSelfie({ dispatch }, selfie) {
    try {
      store.dispatch(
        'loading/setLoadingWithMessage',
        'Seu documento está sendo preparado, por favor, aguarde...',
      )
      console.log('slf', selfie)
      const formData = new FormData()
      if(selfie['unico'] === true) {
        formData.append('base64', selfie["base64"])
        formData.append('encrypted', selfie["encrypted"])
      } else {
        formData.append('data', selfie['capture_data'])
        formData.append('trans_ref', selfie['trans_ref'])
      }
      let birth = localStorage.getItem('bd');
      if (birth != null)
        formData.append('birth', localStorage.getItem('bd'))
      await instance.post('/api/selfie', formData)
      store.dispatch('flux/nextStep', FLOW_PAGES_ENUM.SELFIE, { root: true })
      return true
    } catch (error) {
      if(selfie['unico'] === false)
        store.dispatch('saffe/loadSaffeSDK')
      console.log("ERRROOO", error)
      // let tryNumber = localStorage.getItem('try');
      // if(tryNumber) {
      //   localStorage.setItem("try", (Number(tryNumber) + 1).toString());
      // }
      // let actualValue = localStorage.getItem('try');
      // if(Number(actualValue) % 2 === 0)
      //   dispatch('flux/nextStep', FLOW_PAGES_ENUM.PROPOSAL, { root: true })
      // console.error(error)
      return false
    } finally {
      loading(dispatch, false)
    }
  },

}

const mutations = {
  SET_SELFIE_STATUS(state, selfieStatus) {
    state.selfieStatus = selfieStatus
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
