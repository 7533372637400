const state = {
  loading: false,
  message: '',
}

const getters = {
  loading: (state) => state.loading,
  message: (state) => state.message,
}

const actions = {
  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading)
    commit('SET_MESSAGE', '')
  },

  setLoadingWithMessage({ commit }, message) {
    commit('SET_LOADING', true)
    commit('SET_MESSAGE', message)
  },
}

const mutations = {
  SET_LOADING(state, loading) {
    console.log('SET_LOADING', loading)
    state.loading = loading
  },

  SET_MESSAGE(state, message) {
    console.log('SET_MESSAGE', message)
    state.message = message
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
